import { FeaturePage } from "../components/FeaturePage";
import React from 'react'

export default () => (
  <FeaturePage
    heading1="Monitor and grow your membership database"
    heading2="Manage all of your membership database in one central solution"
    heading3="Monitor and grow your membership database"
    headerBlocks={[
      "Manage all of your membership database in one central solution",
      "Sell and grow your membership database through online self sign-up",
      "Automate the collection of Direct Debit through easy-to-use tools within Trybe",
      "Incentivise your members to purchase more through automated membership credits",
    ]}
    featurePageBlocks={[
      {
        chip: 'Membership management',
        heading: 'Manage your membership database with ease',
        body: [
          "Store your complete membership database within Trybe. From one centralised tool, you have the ability to manage and control all aspects of your customer’s membership.",
          "Stay on top of your membership database through reporting and the ability to filter your membership within Trybe.",
        ]
      },
      {
        chip: 'Membership signup and online portal',
        heading: 'Reduce the barrier to entry with online self-signup and an online member portal',
        body: [
          "Clients are able to complete their registration, pro-rata payment, and Direct Debit mandate in an easy-to-follow online process on your website.",
          "Once they have they created their account, they’ll have access to their own online portal on your website where they can view and manage their membership.",
        ],
      },
      {
        chip: 'Direct Debit',
        heading: 'Eliminate manual hours spent carrying out Direct Debits in another system',
        body: [
            "Carry out all Direct Debit payments through Trybe. Track and manage payments through advanced, automated reporting.",
            "Easily see where Direct Debits have failed and quickly view all revenue captured for the month.",
        ]
      },
      {
        chip: 'Credits',
        heading: 'Incentivise members and sign-ups through automatically distributed membership credits',
        body: [
            "Create credits for your members within Trybe e.g. 10% of spa treatments Monday - Thursday.",
            "These credits will automatically be distributed to members upon sign-up and can continue to be distributed into their membership.",
            "These credits can be automatically claimed by your members as they book online. Use credits as a way of incentivising your membership database into spending more within your facilities."
        ]
      }
    ]}
  />
)
